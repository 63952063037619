import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EpisodePlayer from '../components/EpisodePlayer';
import Breadcrumbs from '../components/Breadcrumbs';
import AnimeNamecard from '../components/animeNameCard'; 
import RelatedAnime from '../components/relatedAnime'; 
import Comments from '../components/Comments';


const decodeTitleFromURL = (sanitizedTitle) => {
  return sanitizedTitle.replace(/-/g, ' ').toLowerCase();
};

const WatchingPage = () => {
  const { animeId, episodeTitle } = useParams(); 
  const [episode, setEpisode] = useState(null);
  const [nextEpisode, setNextEpisode] = useState(null);
  const [prevEpisode, setPrevEpisode] = useState(null);
  const [allEpisodes, setAllEpisodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEpisode = async () => {
      try {
        const decodedTitle = decodeTitleFromURL(episodeTitle);
        const url = `/api/episodes/${animeId}/title/${encodeURIComponent(decodedTitle)}`;
        const response = await fetch(url);

        if (!response.ok) throw new Error('Failed to fetch episode');

        const data = await response.json();
        setEpisode(data);

        const prevResponse = await fetch(`/api/episodes/${animeId}/title/prev/${data.episodeNumber}`);
        if (prevResponse.ok) {
          const prevData = await prevResponse.json();
          setPrevEpisode(prevData);
        }

        const nextResponse = await fetch(`/api/episodes/${animeId}/title/next/${data.episodeNumber}`);
        if (nextResponse.ok) {
          const nextData = await nextResponse.json();
          setNextEpisode(nextData);
        }

        const allEpisodesResponse = await fetch(`/api/episodes/${animeId}/episodes`);
        const allEpisodesData = await allEpisodesResponse.json();
        setAllEpisodes(allEpisodesData);
      } catch (err) {
        console.error('Error fetching episode:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEpisode();
  }, [animeId, episodeTitle]);

  const handleNext = () => {
    if (nextEpisode) {
      const sanitizedNextTitle = encodeURIComponent(nextEpisode.title.trim().toLowerCase().replace(/\s+/g, '-'));
      navigate(`/anime/${animeId}/${sanitizedNextTitle}`);
    }
  };

  const handlePrev = () => {
    if (prevEpisode) {
      const sanitizedPrevTitle = encodeURIComponent(prevEpisode.title.trim().toLowerCase().replace(/\s+/g, '-'));
      navigate(`/anime/${animeId}/${sanitizedPrevTitle}`);
    }
  };

  const handleEpisodeClick = (ep) => {
    const sanitizedTitle = encodeURIComponent(ep.title.trim().toLowerCase().replace(/\s+/g, '-'));
    navigate(`/anime/${animeId}/${sanitizedTitle}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredEpisodes = allEpisodes.filter((ep) =>
    ep.episodeNumber.toString().includes(searchTerm)
  );

  if (loading) return <p className="text-center mt-10 text-text">Loading...</p>;
  if (error) return <p className="text-center text-red-500 mt-10">Error: {error}</p>;

  return (
    <div className="min-h-screen">
      <Breadcrumbs animeId={animeId} animeName={animeId.replace(/-/g, ' ')} episodeTitle={decodeTitleFromURL(episodeTitle)} />

      <div className="w-full max-w-7xl mx-auto p-4 flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-3/4 space-y-4">
          <EpisodePlayer
            episodeData={episode}
            handleNext={handleNext}
            handlePrev={handlePrev}
            prevEpisode={prevEpisode}
            nextEpisode={nextEpisode}
          />


          <input
            type="text"
            placeholder="Find episode number"
            value={searchTerm}
            onChange={handleSearchChange}
            className="mb-4 p-2 rounded-md w-full bg-primary text-text mt-4 focus:ring-2 focus:ring-accent focus:border-accent"
          />
            <div className="grid grid-cols-[repeat(auto-fill,_minmax(50px,_1fr))] gap-4">
              {filteredEpisodes.map((ep) => (
                <button
                  key={ep._id}
                  onClick={() => handleEpisodeClick(ep)}
                  className={`w-16 h-16 rounded-md border-2 text-text ${
                    decodeTitleFromURL(episodeTitle) === decodeTitleFromURL(ep.title)
                      ? 'border-accent'
                      : 'border-transparent'
                  } bg-secondary hover:bg-primary`}
                >
                  {ep.episodeNumber}
                </button>
              ))}
            </div>
          <div className="mt-6">
            <AnimeNamecard animeId={animeId} />
          </div>

          <Comments animeId={animeId} />

          
        </div>

        <div className="w-full lg:w-1/4 space-y-4">
          <RelatedAnime animeId={animeId} />
        </div>
      </div>
    </div>
  );
};

export default WatchingPage;
