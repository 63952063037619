import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

const EpisodePlayer = ({ episodeData, handleNext, handlePrev, prevEpisode, nextEpisode }) => {
  const { user } = useAuth();
  const playerContainerRef = useRef(null);
  const [xpEarned, setXpEarned] = useState(0);
  const [theaterMode, setTheaterMode] = useState(false);

  useEffect(() => {
    if (!user || !episodeData) return;

    const maxXP = Math.floor(episodeData.length / 60);

    const sendXP = async () => {
      if (xpEarned < maxXP) {
        try {
          const response = await fetch('/api/xp/add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({
              xpEarned: 1,
              activity: 'watching',
              details: `Watched episode ${episodeData._id}`,
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to add XP');
          }

          setXpEarned((prevXp) => prevXp + 1);
        } catch (err) {
          console.error('Error adding XP:', err.message);
        }
      }
    };

    const startTracking = () => {
      const intervalId = setInterval(() => {
        sendXP();
      }, 60000); 
      return () => clearInterval(intervalId);
    };

    startTracking();
  }, [episodeData, user, xpEarned]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        theaterMode &&
        playerContainerRef.current &&
        !playerContainerRef.current.contains(event.target)
      ) {
        setTheaterMode(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside); 
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [theaterMode]);

  return (
    <div
      ref={playerContainerRef}
      className={`relative w-full max-w-4xl p-4 transition-all duration-500 ease-in-out`}
      style={{
        zIndex: theaterMode ? 9999 : 'auto',
        position: theaterMode ? 'fixed' : 'relative',
        top: theaterMode ? 0 : 'auto',
        left: theaterMode ? 0 : 'auto',
        width: theaterMode ? '100vw' : 'auto',
        height: theaterMode ? '100vh' : 'auto',
        backgroundColor: theaterMode ? 'black' : 'transparent',
      }}
    >
      <div className="relative pb-[56.25%] h-0">
        <iframe
          src={episodeData.videoUrl}
          title={episodeData.title}
          className="absolute top-0 left-0 w-full h-full border-0"
          allowFullScreen
        ></iframe>
      </div>

      <div className="w-full bg-primary p-2 flex gap-2 items-center mt-2 rounded-md">
        <label className="flex items-center text-text text-sm">
          <input
            type="checkbox"
            checked={theaterMode}
            onChange={() => setTheaterMode(!theaterMode)}
            className={`mr-2 appearance-none w-4 h-4 rounded-md border-2 cursor-pointer ${
              theaterMode ? 'bg-accent' : 'bg-primary'
            }`}
          />
          Theater Mode
        </label>

        <button
          onClick={handlePrev}
          disabled={!prevEpisode}
          className={`px-4 py-2 rounded-md text-text ${prevEpisode ? 'bg-primary hover:bg-primary-dark' : 'bg-gray-400 cursor-not-allowed'}`}
        >
          Previous Episode
        </button>

        <button
          onClick={handleNext}
          disabled={!nextEpisode}
          className={`px-4 py-2 rounded-md text-text ${nextEpisode ? 'bg-primary hover:bg-primary-dark' : 'bg-gray-400 cursor-not-allowed'}`}
        >
          Next Episode
        </button>
      </div>

      {theaterMode && (
        <div
          className="fixed inset-0 bg-black opacity-80 z-50 transition-opacity duration-500 ease-in-out pointer-events-none"
        ></div>
      )}
    </div>
  );
};

export default EpisodePlayer;
