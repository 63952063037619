import React from 'react';
import useAnimeDetails from '../components/AnimeDetail';
import EpisodesList from '../components/EpisodeList';
import WatchlistButton from '../components/WatchlistButton'; 

const transformIdToName = (id) => {
  return id
    .replace(/-/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const AnimePage = () => {
  const { anime, loading, error } = useAnimeDetails();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const releaseYear = new Date(anime.released).getFullYear();

  return (
    <div className="w-full text-text p-4 flex flex-col md:flex-row gap-6">
      <div className="w-full md:w-1/3 h-auto">
        <div className="sticky top-0">
          <img
            src={anime.image}
            alt={transformIdToName(anime.id)}
            className="w-full h-auto object-cover rounded-lg shadow-lg"
          />
        </div>
      </div>

      <div className="w-full md:w-2/3 space-y-6">
        <div className="space-y-2">
          <h1 className="text-3xl md:text-4xl font-bold">{transformIdToName(anime.id)}</h1>
          <div className="text-lg italic text-text">
            <strong>Also known as:</strong> {anime.alias}
          </div>
          <div className="flex flex-wrap gap-2 items-center">
            <span className="text-sm md:text-lg bg-primary text-text px-3 py-1 rounded-full">{anime.types}</span>
            <span className="text-sm md:text-lg bg-primary text-text px-3 py-1 rounded-full">Released: {releaseYear}</span>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <h2 className="text-lg md:text-xl font-semibold border-b-2 border-accent pb-2 mb-2">Anime Details</h2>
        </div>

        <ul className="space-y-2 text-sm md:text-base">
          <li><strong>Genres:</strong> {anime.genres.join(', ')}</li>
          <li><strong>Status:</strong> {anime.status}</li>
          <li><strong>Total Episodes:</strong> {anime.totalEpisodes}</li>
        </ul>

        <p className="text-sm md:text-lg text-text mb-6">{anime.summary}</p>

        <WatchlistButton animeId={anime.id} /> 

        <div>
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg md:text-2xl font-semibold">Episodes</h2>
          </div>
          <EpisodesList animeId={anime.id} />
        </div>
      </div>
    </div>
  );
};

export default AnimePage;
