import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utility function to sanitize the episode title for the URL
const sanitizeTitleForURL = (title) => {
  return title.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase(); // Replaces spaces and special characters with hyphens
};

const EpisodesList = ({ animeId }) => {
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const response = await fetch(`/api/episodes/${animeId}/episodes`);
        if (!response.ok) {
          throw new Error('Failed to fetch episodes');
        }
        const data = await response.json();
        setEpisodes(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (animeId) fetchEpisodes();
  }, [animeId]);

  if (loading) return <p>Loading episodes...</p>;
  if (error) return <p>Error: {error}</p>;

  const handleEpisodeClick = (episode) => {
    const sanitizedTitle = sanitizeTitleForURL(episode.title);
    navigate(`/anime/${animeId}/${sanitizedTitle}`);
  };

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
      {episodes.map((episode, index) => (
        <button
          key={index}
          className="p-2 rounded-lg shadow-lg bg-secondary hover:bg-primary transition duration-300"
          onClick={() => handleEpisodeClick(episode)}
        >
          {`Episode ${index + 1}`}
        </button>
      ))}
    </div>
  );
};

export default EpisodesList;
